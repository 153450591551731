import * as React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import uniq from 'lodash/uniq';
import { ClassTeacherOfLevelStyle2Base } from '../CourseLanding/Detail/ClassTeacherOfLevel2';
import { withHomePageSection } from 'components/withHomePageSection';

const teacherInfoQuery = graphql`
  query OnlineCourseTeacherQuery {
    allGoogleSpreadsheetDolLandingPageMarLevelData(
      filter: { classGroup: { eq: "IELTS Online" } }
    ) {
      edges {
        node {
          levelVideo
          availableSeat
          classCode
          classDate
          classGroup
          classListing
          classTime
          inputLevel
          level
          levelDescription
          levelDisplay
          outputLevel
          startDate
          teacherRw
          teacherSl
          levelSubtext
          levelInputOutputMobile
          levelInputOutputDesktop
        }
      }
    }
  }
`;

const IeltsOnlineCourseTeacherInfo = () => {
  return (
    <StaticQuery
      query={teacherInfoQuery}
      render={data => {
        const levels = uniq(
          data.allGoogleSpreadsheetDolLandingPageMarLevelData.edges.map(
            item => item.node.level
          )
        );
        return (
          <ClassTeacherOfLevelStyle2Base
            noSectionSeparator
            sectionTitle="Đội ngũ giáo viên dạy IELTS Online"
            levels={levels}
            levelGroupName={'IELTS Online'}
          />
        );
      }}
    />
  );
};

export default withHomePageSection(IeltsOnlineCourseTeacherInfo);
