/* eslint-disable react/prop-types */
import * as React from 'react';

import format from 'date-fns/format';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { formatDayOfWeek, formatClassTime } from 'utils/dateFormat';

import { ScheduleTable } from '../CourseLanding/Detail/ClassScheduleOfLevel';
import { withHomePageSection } from 'components/withHomePageSection';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { getName } from 'utils';

const Wrapper = styled.div`
  .schedule-title {
    margin-bottom: 24px;
    ${fromScreen(1144)} {
      text-align: center;
      margin-bottom: 40px;
    }
  }
`;

const studentOnlineCourseScheduleQuery = graphql`
  query StudentOnlineCourseScheduleQuery {
    allGoogleSpreadsheetDolLandingPageMarLevelData(
      filter: {
        classListing: { eq: "listed" }
        classGroup: { eq: "IELTS Online" }
      }
      sort: { fields: [levelDisplay], order: ASC }
    ) {
      nodes {
        levelDisplay
        id
        startDate
        classTime
        classDate
        classStatus
        scheduleBranchName
      }
    }
  }
`;

export const toClassSchedule = (data, level) => {
  return data.allGoogleSpreadsheetDolLandingPageMarLevelData.nodes
    .filter(item => item.level === level && Boolean(item.classDate))
    .map(({ startDate, classDate, classStatus, classTime, levelDisplay }) => {
      return {
        dateOpening: format(new Date(startDate), 'dd/MM/yyyy'),
        dateOpeningMobile: format(new Date(startDate), 'dd/MM/yyyy'),
        dayOfWeek: formatDayOfWeek(classDate),
        classStatus: classStatus,
        duration: formatClassTime(classTime),
        scheduleBranchName: getName(levelDisplay)
      };
    });
};

const StudentReviews = () => {
  const data = toClassSchedule(
    useStaticQuery(studentOnlineCourseScheduleQuery)
  );

  return (
    <Wrapper>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        className="schedule-title"
        as="h2"
      >
        Lịch học lớp IELTS Online
      </Typography>
      <ScheduleTable data={data} onlineCourse />
    </Wrapper>
  );
};

export default withHomePageSection(StudentReviews);
