/* eslint-disable react/prop-types */
import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { DifferencesCommon } from '../CourseLanding/DifferencesHome';
import { withHomePageSection } from 'components/withHomePageSection';

const studentCourseDifferencesQuery = graphql`
  query studentCourseDifferencesQuery {
    gcms {
      jsonContanier(where: { name: "LandingOnlineCourse_Differences_Dev" }) {
        content
      }
    }
  }
`;

const GCMSStudentCourseDifferencesMain = () => {
  return (
    <StaticQuery
      query={studentCourseDifferencesQuery}
      render={data => {
        return (
          <DifferencesCommon
            data={data.gcms.jsonContanier.content}
            title="6 điểm khác biệt của khóa Online tại DOL"
            mobileTitle="6 điểm khác biệt của DOL"
          />
        );
      }}
    />
  );
};

export default withHomePageSection(GCMSStudentCourseDifferencesMain);
