import * as React from 'react';

function IconChevronUp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.657 16.243l1.414-1.415L12 7.758l-7.071 7.07 1.414 1.415L12 10.586l5.657 5.657z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconChevronUp = React.memo(IconChevronUp);
export default MemoIconChevronUp;
