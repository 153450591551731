import * as React from 'react';
import styled from 'styled-components';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import FixedTopBarScholarshipOnline from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipOnline';
import GCMSStudentCourseDifferences from 'shared/StudentCourseLanding/GCMSStudentCourseDifferences';
import IeltsOnlineCourseSchedule from 'shared/StudentCourseLanding/IeltsOnlineCourseSchedule';
import IeltsOnlineCourseTeacherInfo from 'shared/StudentCourseLanding/IeltsOnlineCourseTeacherInfo';
import StudentReviews from 'shared/StudentCourseLanding/StudentReviews';
import { FAQ } from 'shared/CourseLanding/Detail/CourseFAQ';
import { LevelContext } from '../shared/Marketing/Online/DolRegister/components/withProgramByLevel';
import HallOfFame from 'shared/HallOfFame';
import { StudentCourseLandingHeroBanner } from '../shared/StudentCourseLanding/StudentCourseLandingHeroBanner';
import { fromScreen } from '../utils/media-query/responsive.util';
import { withHomePageSection } from 'components/withHomePageSection';

const Main = styled.div`
  &&& {
    section {
      padding: 32px 0;
      ${fromScreen(776)} {
        padding: 80px 0;
        &:first-child {
          padding: 100px 0 80px 0;
        }
        &:last-child {
          padding: 80px 0 100px 0;
        }
      }
    }
  }
`;

const HallOfFameSection = withHomePageSection(() => <HallOfFame showTitle />);

const StudentReviewSection = withHomePageSection(() => (
  <StudentReviews
    program="IELTS"
    title="Review học viên khóa IELTS tại DOL"
    columns={{ default: 3, 1143: 2, 776: 1 }}
  />
));

const StudentCoursePage = ({ location }) => {
  return (
    <LevelContext.Provider value="Online">
      <MainLayout
        renderCustomHeader={() => <HeaderSchedulePage />}
        noHeader
        hideSideCTA
      >
        <MySEO
          slug={location.pathname}
          h1="IELTS Online"
          hidePostfix
          featureImg="ZSkB3XTHQOSExx4YeHn3"
          title="Khóa học IELTS Online chất lượng, cam kết đầu ra tại DOL ENGLISH"
          description="Học IELTS Online trải nghiêm như offline với học phí chỉ 2/3 cùng phương pháp Linear Thinking tại Dol English...Đăng ký ngay!"
        />
        <FixedTopBarScholarshipOnline />
        <StudentCourseLandingHeroBanner
          data={{
            imgHandle: 'aCeCPLklRPKznNcNXLEe',
            titles: ['IELTS Online'],
            description: [
              'Học IELTS Online xịn như Offline với phương pháp Linearthinking, giáo viên tận tâm, nền tảng công nghệ hỗ trợ, đảm bảo đầu ra.'
            ]
          }}
        />
        <Body />
      </MainLayout>
    </LevelContext.Provider>
  );
};

const Body = () => {
  return (
    <Main>
      <GCMSStudentCourseDifferences />
      <IeltsOnlineCourseTeacherInfo />
      <StudentReviewSection
        program="IELTS"
        columns={{ default: 3, 1143: 2, 776: 1 }}
        bubble={[{ type: 'text', text: 'Review học viên khóa IELTS tại DOL' }]}
      />
      <HallOfFameSection />
      <IeltsOnlineCourseSchedule />
      <FAQ landingPage />
    </Main>
  );
};

export default StudentCoursePage;
