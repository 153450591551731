/* eslint-disable react/prop-types */
import * as React from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';
import DolInfo from 'shared/DolInfo';
import Button from '@dolstaff/shared/es/Button';
import { TitleIcon } from 'components/TitleIcon';
import { withHomePageSection } from 'components/withHomePageSection';

import { LazyImage } from 'components/LazyImage';
import { colorsV2 } from 'style/colors-v2';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { Typography } from '../../components/DesignSystemV2/Typography';
import ProgressiveImage from 'react-progressive-image';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { UserPixelTracking } from 'utils/userPixelTracking';

const InfoSectionWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 24px;
  .btn-action {
    border-radius: 8px;
  }
  .highlight {
    z-index: 1;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      background: ${colorsV2.yellow100};
      bottom: 5px;
      left: 0;
      height: 10px;
      width: 100%;
      opacity: 0.3;
      z-index: -1;
    }
  }
`;

const toMultipleLines = lines => {
  return (
    <>
      {lines.map(line => (
        <>
          {line}
          <br />
        </>
      ))}
    </>
  );
};

const InfoSection = ({ data }) => {
  const onClick = () => {
    UserPixelTracking.clickEvent();
  };

  return (
    <InfoSectionWrapper>
      <TitleIcon iconHandle="RY1uavGWQqW2aZ0GGJDo">
        <Typography variant="bold/40-48" tabletVariant="bold/48-60" v3 as="h1">
          IELTS <span className="highlight">Online</span>
        </Typography>
      </TitleIcon>

      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        color={colorsV2.black80}
      >
        {toMultipleLines(data.description)}
      </Typography>
      <DolInfo>
        {({ dolInfo }) => (
          <Link
            target="_blank"
            href={dolInfo.messenger.href}
            rel="noreferrer noopener"
          >
            <Button
              size="large"
              className="btn-action"
              type="primary"
              marginBottom="0"
              onClick={onClick}
            >
              Nhắn tin DOL qua FB
            </Button>
          </Link>
        )}
      </DolInfo>
    </InfoSectionWrapper>
  );
};

const HeaderSubPageWrapper = styled.div`
  padding: 32px 0;
  .sub-banner {
    display: none;
  }

  .image-block {
    position: relative;
    .icon {
      display: none;
      position: absolute;
      &.top {
        top: 0;
        right: 0;
      }
      &.center {
        left: -25px;
      }
    }
    .sub-banner {
      width: 486px;
    }
  }

  ${fromScreen(776)} {
    display: grid;
    align-items: center;
    padding: 100px 0;
    grid-column-gap: 24px;
    grid-template-columns: 347px minmax(0, 1fr);
    .sub-banner {
      display: block;
    }
  }

  ${fromScreen(1144)} {
    grid-template-columns: 513px minmax(0, 1fr);
    grid-column-gap: 32px;
    .image-block {
      .icon {
        display: block;
      }
    }
  }
`;
export const StudentCourseLandingHeroBanner = withHomePageSection(
  ({ data }) => {
    const imgHandle = data.imgHandle;
    return (
      <HeaderSubPageWrapper>
        <InfoSection data={data} />
        <div className="image-block">
          <ProgressiveImage
            src={getAssetLinkByHandle({ handle: imgHandle })}
            placeholder={getAssetLinkByHandle({ handle: imgHandle, width: 10 })}
          >
            {src => (
              <img className="sub-banner" src={src} alt="Ielts Online DOL" />
            )}
          </ProgressiveImage>
          <LazyImage
            handle="1KmuTBLmS4uEu98y6Tp3"
            className="icon top"
            alt="icon-star"
          />
          <LazyImage
            handle="pZtoUY3mStuWmaQ5lBx6"
            className="icon center"
            alt="icon-arrow"
          />
        </div>
      </HeaderSubPageWrapper>
    );
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
