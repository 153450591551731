import * as React from 'react';

import { flatMap } from 'lodash';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { withMarketingSection } from 'components/withMarketingSection';
import {
  getUniqueTeachers,
  useAllTeacherData
} from 'shared/DolTeachers/AllDolTeachersV2';
import { TeacherTab } from 'shared/DolTeachers/TeacherTab';
import { TeachersTabMobile } from 'shared/DolTeachers/TeacherTabMobile';
import { colorsV2 } from '../../../style/colors-v2';
import { ResponsiveLayout } from '../../Layout/ResponsiveLayout';
import { VideoPlayer } from '../../Linearthinking/YoutubeVideoCourses';
import Modal from '../Modal';
import { SectionSeparator } from '../SectionSeparator';

const Main = styled.div`
  .view-full-schedule-btn {
    background-color: ${colorsV2.white100};
    border: 2px solid ${colorsV2.primary100};
    box-sizing: border-box;
    border-radius: 8px;
  }
`;

export const ClassTeacherOfLevelStyle2Base = ({
  levels,
  noSectionSeparator,
  sectionTitle = 'Đội ngũ giáo viên'
}) => {
  const { getTeacherOfLevel } = useAllTeacherData();

  const teachers = getUniqueTeachers(
    flatMap(levels.map(levelName => getTeacherOfLevel(levelName)))
  );
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <Main id="class-teacher-of-level">
      {!noSectionSeparator && <SectionSeparator />}
      <>
        <ResponsiveLayout
          breakpointWithComponents={[
            {
              breakpoint: 775,
              renderComponent: (
                <TeachersTabMobile
                  sectionTitle={sectionTitle}
                  data={teachers}
                  setVideoUrl={setVideoUrl}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  setShowModal={setShowModal}
                  viewMoreUrl="/linearteachers"
                />
              )
            },
            {
              breakpoint: 776,
              ssrDefault: true,
              renderComponent: (
                <TeacherTab
                  sectionTitle={sectionTitle}
                  data={teachers}
                  setVideoUrl={setVideoUrl}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  setShowModal={setShowModal}
                  hasBgColor={false}
                />
              )
            }
          ]}
        />

        <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
          <VideoPlayer videoUrl={videoUrl} autoPlay />
        </Modal>
      </>
    </Main>
  );
};

export const ClassTeacherOfLevelStyle2 = withMarketingSection(
  ClassTeacherOfLevelStyle2Base,
  { selfish: true, style: { backgroundColor: colorsV2.white100 } }
);
